import React, { Component } from 'react'
import './App.css'

class App extends Component {
  constructor(props) {
      super(props)
      this.state = {
        //words: ['enter up to 7 letters to see word suggestions'],
        words: [],
        letters: '',
        count: 0
      }

      document.body.style = 'background: lightblue;';

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({letters: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    // only accept ascii letters
    var l1 = this.state.letters.replace(/[^a-zA-Z]/g, '');
    // limit to first 7 seen
    const l = l1.substring(0,7);

    console.log('letters were submitted:' + this.state.letters + " used:" + l);
    this.setState( { letters: l } );

    //var m ='letters submitted: ' + l;
    //this.setState( { msg: m } );

    //const APIurl = 'http://localhost:5000';
    const APIurl = 'https://n34u85cc10.execute-api.us-west-2.amazonaws.com/dev';
    const url = APIurl + '/suggest?letters=' + l;

    fetch(url)
      /*.then(function(response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
      })
      */
      .then(result => result.json())
      .then(result => {
        this.setState({
          words: result.words,
          count: result.words.length
        })
      })
      .catch( err => {
        console.error(err);
      })
  }

  render() {
    const words = this.state.words;

    let result = words.map((entry, index) => {
          return <li key={index}>{entry}</li>
    })

    return (
        <div>
        <div className='parent'>
          <div className="inline-block-child App-toplabel">wordsuggest7</div>
          <div className="inline-block-child App-howto">Enter up to 7 letters to see word suggestions</div>
        </div>
        <div>
      <form onSubmit={this.handleSubmit}>
        <input type="text" className="App-input" placeholder='abcdefg' value={this.state.letters} onChange={this.handleChange} />
        <input type="submit" className="App-button" value="Submit up to 7 letters" />
      </form> 
        </div>

      <p className="w3-large App-list">letters <b>{this.state.letters}</b> resulted in <b>{this.state.count}</b> words</p>
      <ul className="w3-ul App-list">{result}</ul>

        </div>
    );
  }
}

export default App
